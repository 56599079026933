body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.active {
  transition: .4s;
  cursor: pointer;
  color: var(--menu-color);
  padding: .2em !important;
  border-left: 5px solid;
  border-right: 5px solid;
  border-color: var(--background-color-blue);
  transition: .4s;
}

.active-link, .active-link svg, .active-link p ,.active-link a{
  color: var(--key-color-2) !important;
  transform: scale(1);
  pointer-events: none;
  transition: .4s;
}

#SvgjsText1038 {
  font-weight: bold !important;
  font-family: 'Montserrat, sans-serif';
}

#SvgjsText1040 {
  font-weight: bold !important;
  font-family: 'Montserrat, sans-serif';
}

#SvgjsText1042 {
  font-weight: bold !important;
  font-family: 'Montserrat, sans-serif';
}

#SvgjsText1093 {
  font-weight: bold !important;
  font-family: 'Montserrat, sans-serif';
}

#SvgjsText1095 {
  font-weight: bold !important;
  font-family: 'Montserrat, sans-serif';
}

#SvgjsText1097 {
  font-weight: bold !important;
  font-family: 'Montserrat, sans-serif';
}

.apexcharts-xaxis-label {
  font-weight: bold !important;
  font-family: 'Montserrat, sans-serif';
}